@font-face {
  font-family: "Aurebesh-E";
  src:
    local("Aurebesh-E"),
    url("./data/Aurebesh-English.ttf") format("truetype");
}

@font-face {
  font-family: "Tektur";
  src:
    local("Tektur"),
    url("./data/tektur-latin-500-normal.ttf") format("truetype");
}

@media print {
  body {
    zoom: 60%;
  }

  .no-print {
    display: none !important;
  }

  .print-black {
    fill: black !important;
  }

  .MuiPaper-root {
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
  }

  .MuiGrid-grid-lg-1 {
    flex-basis: calc(100% / 12) !important;
    max-width: calc(100% / 12) !important;
  }

  .MuiGrid-grid-lg-2 {
    flex-basis: calc(100% / 6) !important;
    max-width: calc(100% / 6) !important;
  }

  .MuiGrid-grid-lg-3 {
    flex-basis: calc(100% / 4) !important;
    max-width: calc(100% / 4) !important;
  }

  .MuiGrid-grid-lg-4 {
    flex-basis: calc(100% / 3) !important;
    max-width: calc(100% / 3) !important;
  }

  .MuiGrid-grid-lg-5 {
    flex-basis: calc(100% / 2.4) !important;
    max-width: calc(100% / 2.4) !important;
  }

  .MuiGrid-grid-lg-6 {
    flex-basis: calc(100% / 2) !important;
    max-width: calc(100% / 2) !important;
  }
}

.dice-pool {
  display: inline-flex;
  align-items: center;
}

.dice-pool span {
  font-size: 12px;
  margin-right: 2px;
}